import {Component, Inject} from '@angular/core'
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog'

import {ConfirmModalCfg} from '@schemes/ui/confirm-modal-cfg'

@Component({
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {

  cfg: ConfirmModalCfg
  loading: boolean

  constructor(
    @Inject(DIALOG_DATA) public data: { cfg: ConfirmModalCfg },
    private dialogRef: DialogRef<void>,
  ) {
    this.cfg = data.cfg
  }

  hide() {
    if (this.loading) return
    this.dialogRef.close()
  }

  cancel() {
    this.cfg.onCancel?.()
    this.hide()
  }

  confirm() {
    if (this.cfg.observeBeforeConfirm) {
      this.loading = true

      this.cfg.observeBeforeConfirm.subscribe({
        next: () => {
          this.loading = false
          this.cfg.onConfirm?.()
          this.hide()
        },
        error: () => {
          this.loading = false
        },
      })

      return
    }

    this.cfg.onConfirm?.()
    this.hide()
  }

  get title() {
    return this.cfg.title
  }

  get message() {
    return this.cfg.message
  }

  get cancelText() {
    return this.cfg.cancelText ?? 'Отмена'
  }

  get confirmText() {
    return this.cfg.confirmText ?? 'ОК'
  }

  get confirmTextClass() {
    return this.cfg.confirmTextClass ?? ''
  }

  get icon() {
    return this.cfg.icon ?? 'blue'
  }
}
